<template>
  <div>
    <b-card
      title="Usuarios inactivos"
      overlay
      :img-src="cardImage"
      img-alt="Card Image"
      text-variant="white"
      class="cardHeader border-0"
      v-if="windowWidth > 500"
    >
      <b-card-text>
        Un breve texto con indicaciones para buscar los productos en el filtrado
        que se muestra a continuación.
      </b-card-text></b-card
    >
    <b-card title="">
      <b-row>
        <b-col md="4" sm="4" class="my-1">
          <b-form-group class="mb-0" label-size="lg" label-for="perPageSelect">
            <b-input-group size="md">
              <b-form-input id="filterInput" v-model="search" type="search" />
              <b-input-group-append>
                <b-button variant="primary" @click="listUsers()">
                  {{ $i18n.messages.Buscar }}
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col cols="12 my-2">
          <b-table
            striped
            responsive
            :items="items"
            :fields="fields"
            @sort-changed="handleSortChange"
          >
            <template #cell(actions)="data">
              <div
                style=" display: flex;justify-content: flex-end; width:100%; padding:0;"
              >
                <router-link
                  :to="{
                    name: 'user',
                    params: { user: data.item },
                  }"
                >
                  <b-button class="btn-action" style="min-width: 100px;">
                    {{ $i18n.messages.DetailUser }}
                  </b-button>
                </router-link>
              </div>
            </template>
          </b-table>
        </b-col>

        <b-col cols="12">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalItems"
            :per-page="perPage"
            align="center"
            size="sm"
            class="my-0"
          />
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BTable,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCard,
  BCardText,
} from "bootstrap-vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  components: {
    BCard,
    BCardText,
    BTable,
    BPagination,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
  },
  data() {
    return this.customData();
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  computed: {
    ...mapGetters({
      items: "users/getListUsersInactive",
      totalItems: "users/getTotalUsersInactive",

      //  productAdd: "products/getProductAdd",
      user: "auth/getUser",
    }),
  },
  created() {
    this.listUsers();
  },
  watch: {
    windowWidth(newWidth, oldWidth) {
      this.txt = `it changed to ${newWidth} from ${oldWidth}`;
    },
    currentPage() {
      this.listUsers();
    },
    perPage() {
      this.listUsers();
    },
    sortBy() {
      this.listUsers();
    },
    sortDesc() {
      this.listUsers();
    },
  },
  methods: {
    ...mapActions({
      searchUsersActive: "users/searchUsersInactive",
    }),
    ...mapMutations({
      setLoading: "app/setLoading",
    }),
    handleSortChange(context) {
      // this is called when b-table with ref "mytable" hears the 'sort-changed' event
      // that it has emitted

      // sorting logic goes here
      console.log(context);
    },
    customData() {
      return {
        pageOptions: [10, 15, 20],
        cardImage: require("@/assets/images/pages/image-header-productos.svg"),
        sortBy: "",
        sortDesc: "+",
        sortOptions: [
          { text: "Stock", value: "stock" },
          { text: "Date", value: "date" },
        ],
        sortOptionsAgent: [{ text: "Date", value: "date" }],
        orderOptions: [
          { text: "Desc", value: "+" },
          { text: "Asc", value: "-" },
        ],
        currentPage: 1,
        perPage: 12,
        search: "",
        sort: "",
        family: "",
        size: "",
        windowWidth: window.innerWidth,
        fields: [
          {
            key: "name",
            label: "Nombre",
            tdClass: "textcell",
          },
          {
            key: "CIF",
            label: "CIF",
            tdClass: "textcell",
          },
          {
            key: "companyName",
            label: "Empresa",
            tdClass: "textcell",
          },
          {
            key: "roleName",
            label: "Rol",
            tdClass: "textcell",
          },
          {
            key: "statusName",
            label: "Estado",
            tdClass: "textcell",
          },

          {
            key: "registeredAt",
            label: "Fecha de registro",
            tdClass: "numcell",
            formatter(value) {
              if (value === null) {
                return "-";
              } else {
                var str = value.split("T")[0];
                var hr = value.split("T")[1];
                var hr2 = hr.split(".")[0];
                return (
                  str.split("-")[2] +
                  "/" +
                  str.split("-")[1] +
                  "/" +
                  str.split("-")[0] +
                  " " +
                  hr2
                );
              }
            },
          },

          {
            key: "Actions",
            label: "",
          },
        ],
      };
    },
    onResize() {
      this.windowWidth = window.innerWidth;
      console.log(window.innerWidth);
    },
    listUsers() {
      const { search, currentPage, perPage, sortBy, sortDesc } = this;

      localStorage.setItem("searchProd", JSON.stringify(search));
      localStorage.setItem("currentPageProd", JSON.stringify(currentPage));
      localStorage.setItem("sortByProd", JSON.stringify(sortBy));
      localStorage.setItem("sortDescProd", JSON.stringify(sortDesc));

      const limit = 12;
      const offset = (currentPage - 1) * 12;
      let sort = "";
      if (sortBy) {
        sort = sortDesc + sortBy;
      }
      this.setLoading(true);
      this.searchUsersActive({
        search,
        limit,
        offset,
        sort,
        currentPage,
      }).then(() => {
        this.setLoading(false);
      });
    },
    resetFilters() {
      Object.assign(this.$data, this.customData());
    },
  },
};
</script>
